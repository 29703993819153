import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Heading } from "../heading";
import { PageContent } from "../pageContent";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import TodayIcon from "@mui/icons-material/Today";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import XIcon from "@mui/icons-material/X";
import EmailIcon from "@mui/icons-material/Email";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ForumIcon from "@mui/icons-material/Forum";
import {
  Container,
  Grid,
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { Map } from "../googleMap";
import { DocsUploadWithValidation } from "../Dropzone/DocsUploadWithValidation";
import RefreshIcon from "@mui/icons-material/Refresh";
import { validateForm } from "../../config/validation";
import { SelectInputField } from "../SelectInputField";
import { toast } from "react-toastify";

const ContactusListData = [
  {
    id: 1,
    label: "unified phone number",
    number: "011-2896664",
    icon: <PhoneInTalkIcon />,
  },
  {
    id: 2,
    label: "Service availability time",
    number: "from Sunday through Thursday (from 8am to 2:30pm)",
    icon: <TodayIcon />,
    style: { borderLeft: " 1px solid #ddd", borderRight: "1px solid #ddd" },
  },
  {
    id: 3,
    label: "Response time",
    number: "Immediate",
    icon: <MoreTimeIcon />,
  },
  {
    id: 4,
    label: "Client's service",
    number: "@tvtcweb_ask",
    icon: <XIcon />,
    path: "https://x.com/tvtcweb_ask",
  },
  {
    id: 5,
    label: "Service availability time",
    number: "from Sunday through Thursday (from 8am to 2:30pm)",
    icon: <TodayIcon />,
    path: "https://x.com/tvtcweb_ask",
    style: { borderLeft: " 1px solid #ddd", borderRight: "1px solid #ddd" },
  },
  {
    id: 6,
    label: "Response time",
    number: "Immediate",
    icon: <MoreTimeIcon />,
    path: "https://x.com/tvtcweb_ask",
  },
  {
    id: 7,
    label: "Email",
    number: "cso@tvtc.gov.sa",
    icon: <EmailIcon />,
    path: "mailto:cso@tvtc.gov.sa",
  },
  {
    id: 8,
    label: "Service availability time",
    number: "from Sunday through Thursday (from 8am to 2:30pm)",
    icon: <TodayIcon />,
    path: "mailto:cso@tvtc.gov.sa",
    style: { borderLeft: " 1px solid #ddd", borderRight: "1px solid #ddd" },
  },
  {
    id: 9,
    label: "Response time",
    number: "Working day",
    icon: <MoreTimeIcon />,
    path: "mailto:cso@tvtc.gov.sa",
  },
  {
    id: 10,
    label: "Contact us",
    number: "https://crm.tvtc.gov.sa/crm/index.php",
    icon: <ContactPhoneIcon />,
    path: "https://crm.tvtc.gov.sa/crm/index.php",
  },
  {
    id: 11,
    label: "Service availability time",
    number: "from Sunday through Thursday (from 8am to 2:30pm)",
    icon: <TodayIcon />,
    path: "https://crm.tvtc.gov.sa/crm/index.php",
    style: { borderLeft: " 1px solid #ddd", borderRight: "1px solid #ddd" },
  },
  {
    id: 12,
    label: "Response time",
    number: "Working day",
    icon: <MoreTimeIcon />,
    path: "https://crm.tvtc.gov.sa/crm/index.php",
  },
  {
    id: 13,
    label: "Technical support in sign language",
    number: "https://deaf.dga.gov.sa/",
    icon: <SupportAgentIcon />,
    path: "https://deaf.dga.gov.sa/",
  },
  {
    id: 14,
    label: "Service availability time",
    number: "from Sunday through Thursday (from 8am to 2:30pm)",
    icon: <TodayIcon />,
    path: "https://deaf.dga.gov.sa/",
    style: { borderLeft: " 1px solid #ddd", borderRight: "1px solid #ddd" },
  },
  {
    id: 15,
    label: "Response time",
    number: "Immediate",
    icon: <MoreTimeIcon />,
    path: "https://deaf.dga.gov.sa/",
  },
  {
    id: 16,
    label: "Beneficiaries Interaction Center (Amer)",
    number: "www.my.gov.sa/wps/portal/snp/content/amer",
    icon: <ForumIcon />,
    path: "https://www.my.gov.sa/wps/portal/snp/content/amer",
  },
  {
    id: 17,
    label: "Service availability time",
    number: "from Sunday through Thursday (from 8am to 2:30pm)",
    icon: <TodayIcon />,
    path: "https://www.my.gov.sa/wps/portal/snp/content/amer",
    style: { borderLeft: " 1px solid #ddd", borderRight: "1px solid #ddd" },
  },
  {
    id: 18,
    label: "Response time",
    number: "Working day",
    icon: <MoreTimeIcon />,
    path: "https://www.my.gov.sa/wps/portal/snp/content/amer",
  },
];
export const Contactus = () => {
  const { t } = useTranslation();

  return (
    <PageContent>
      <Container>
        {/* <Heading textAlign={language === "ar" ? "right" : "left"}> */}
        <Heading className="contact-us-heading">{t(`contact.heading`)}</Heading>
        <Grid container className="contact-us-container">
          {ContactusListData.map((data) => (
            <Grid
              key={data?.id}
              item
              component="a"
              href={data?.path && data?.path}
              target={data?.path && "_blank"}
              md={4}
              textAlign="center"
              sx={{
                marginBottom: "50px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "170px",
                padding: "0 20px",
                textDecoration: "none",
                cursor: data?.path ? "pointer" : "default",
                ...data?.style,
              }}
            >
              <p
                style={{ color: "#1fbbb4", margin: "0px" }}
                className="contact-us-icon"
              >
                {data?.icon}
              </p>
              <p
                style={{
                  color: "#125c86",
                  marginBottom: "5px",
                  fontWeight: 600,
                }}
              >
                {data?.label}
              </p>
              <span
                style={{
                  color: "#1fbbb4",
                  fontWeight: 500,
                  wordBreak: "break-all",
                  fontSize: "15px",
                }}
              >
                {data?.number}
              </span>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Map />
          </Grid>
        </Grid>
        <ContactForm />
      </Container>
    </PageContent>
  );
};

const ContactForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    mobile: "",
    inquiryType: "",
    messageTitle: "",
    message: "",
    attachments: "",
    verificationCode: "",
    newCaptcha: "",
  });
  const [error, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile" && !/^\d*$/.test(value)) {
      return; // Prevent non-numeric input
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...error,
      [name]: "", // Clear the error for the current field
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateForm(formData);
    if (!isValid) {
      setErrors(errors);
    } else {
      toast.success(
        "Thank you for reaching out! Our team will get back to you shortly.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        },
      );
      setFormData({
        email: "",
        name: "",
        mobile: "",
        inquiryType: "",
        messageTitle: "",
        message: "",
        attachments: "",
        verificationCode: "",
        newCaptcha: "",
      });
      setErrors({});
    }
  };

  const [selectedFiles, setSelectedFiles] = useState();
  const handleOnDropImage = (files) => {
    setSelectedFiles(files[0]);
    setFormData({
      ...formData,
      attachments: files[0],
    });
  };

  const canvasRef = useRef(null);

  const generateCaptcha = () => {
    const chars = "0123456789";
    let newCaptcha = "";
    for (let i = 0; i < 5; i++) {
      newCaptcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setFormData({
      ...formData,
      newCaptcha: newCaptcha,
    });
    drawCaptcha(newCaptcha);
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const drawCaptcha = (text) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.font = "25px italic";
        ctx.fillStyle = "#003366";
        ctx.fillText(text, 10, 30);
      }
    }
  };

  const handleRefresh = () => {
    generateCaptcha();
  };

  return (
    <Box className="contact-us-container" mt={2}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            label="E-mail"
            name="email"
            type="email"
            required
            margin="normal"
            value={formData.email}
            onChange={handleChange}
            error={
              formData?.email
                ? error?.email
                  ? !!error?.email
                  : false
                : !!error?.email
            }
            helperText={
              formData?.email
                ? error?.email
                  ? error?.email
                  : ""
                : error?.email
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            required
            margin="normal"
            value={formData.name}
            onChange={handleChange}
            error={formData?.name ? false : !!error?.name}
            helperText={formData?.name ? "" : error?.name}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            label="Mobile Number"
            name="mobile"
            required
            margin="normal"
            value={formData.mobile}
            onChange={handleChange}
            error={
              formData?.mobile
                ? error?.mobile
                  ? !!error?.mobile
                  : false
                : !!error?.mobile
            }
            helperText={
              formData?.mobile
                ? error?.mobile
                  ? error?.mobile
                  : ""
                : error?.mobile
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectInputField
            label="Inquiry Type"
            name="inquiryType"
            value={formData.inquiryType}
            errors={error?.inquiryType}
            handleChange={handleChange}
          >
            <MenuItem value="">-- Choose --</MenuItem>
            <MenuItem value="general">General Inquiry</MenuItem>
            <MenuItem value="support">Support</MenuItem>
            <MenuItem value="feedback">Feedback</MenuItem>
          </SelectInputField>
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
            fullWidth
            label="Message Title"
            name="messageTitle"
            required
            margin="normal"
            value={formData.messageTitle}
            onChange={handleChange}
            error={formData?.messageTitle ? false : !!error?.messageTitle}
            helperText={formData?.messageTitle ? "" : error?.messageTitle}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
            sx={{ "& .MuiInputBase-root": { p: 0 } }}
            fullWidth
            label="Message"
            name="message"
            multiline
            rows={4}
            required
            margin="normal"
            value={formData.message}
            onChange={handleChange}
            error={formData?.message ? false : !!error?.message}
            helperText={formData?.message ? "" : error?.message}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <>
            <DocsUploadWithValidation
              label="Attachments"
              value={
                selectedFiles?.name
                  ? selectedFiles?.name
                  : formData?.attachments?.toString()
              }
              // errors={errors?.image}
              accepted={{ "image/*": [], "application/*": [] }}
              onDrop={handleOnDropImage}
            />
            <Box>
              <Typography variant="body2" noWrap>
                {selectedFiles?.name}
              </Typography>
            </Box>
          </>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Box display="flex" alignItems="center" gap={1}>
            <canvas
              ref={canvasRef}
              width={100}
              height={40}
              style={{
                backgroundImage: "url(/static/images/bg_captcha.png)",
                backgroundRepeat: "no-repeat",
                letterSpacing: "2px",
                fontStyle: "italic",
              }}
            />
            <IconButton onClick={handleRefresh}>
              <RefreshIcon />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            label="Verification Code"
            name="verificationCode"
            required
            margin="normal"
            value={formData.verificationCode}
            onChange={handleChange}
            error={
              formData?.verificationCode
                ? error?.verificationCode
                  ? !!error?.verificationCode
                  : false
                : !!error?.verificationCode
            }
            helperText={
              formData?.verificationCode
                ? error?.verificationCode
                  ? error?.verificationCode
                  : ""
                : error?.verificationCode
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Button
            size="small"
            sx={{
              background: "linear-gradient(to right, #1fbbb4, #abd8ae)",
              color: "#fff",
              borderColor: "#fff",
              "&:hover": {
                background: "linear-gradient(to right, #1fbbb4, #1fbbb4)",
              },
            }}
            onClick={handleSubmit}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactForm;
