import { Box, Container, Link, Stack, Typography } from "@mui/material";
import React from "react";
import footerLogo from "../../assets/images/home/footer_logo.svg";
import { ReactComponent as SnapchatIcon } from "../../assets/images/icons/snapchat.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/images/icons/linkedin.svg";
import { ReactComponent as FacebookIcon } from "../../assets/images/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/icons/instagram.svg";
import { ReactComponent as TwitterIcon } from "../../assets/images/icons/twitter.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/images/icons/youtube.svg";

function Footer() {
  return (
    <>
      <Box
        sx={{
          background: "linear-gradient(to right, #4DADAD, #3564A6)",
        }}
      >
        <Box
          sx={{
            backgroundImage: "url(/static/images/footer.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <footer className="footer">
            <Container>
              <section className="footer-top">
                <Box sx={{ mb: 5 }}>
                  <img src={footerLogo} alt="footer logo" />
                </Box>
                <Box>
                  <Stack spacing={3} className="footer-item">
                    <Link href="#" underline="none" sx={{ color: "#fff" }}>
                      <Typography>+966 123 456 789</Typography>
                    </Link>
                    <Link href="#" underline="none" sx={{ color: "#fff" }}>
                      <Typography>info@institution.sa</Typography>
                    </Link>
                    <Link href="#" underline="none" sx={{ color: "#fff" }}>
                      <Typography>Riyadh, Saudi Arabia</Typography>
                    </Link>
                  </Stack>
                </Box>
              </section>
              <section className="footer-bottom">
                <Box
                  sx={{
                    color: "#ffffff",
                    display: { xs: "block", sm: "flex" },
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box component="div">
                    <Typography>
                      © [Institution Name] 2024. All rights reserved.
                    </Typography>
                  </Box>
                  <Box component="div">
                    <Box
                      sx={{
                        display: { xs: "flex", sm: "flex" },
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <Link href="#" sx={{ color: "#fff" }}>
                        Data protection
                      </Link>
                      <Link href="#" sx={{ color: "#fff" }}>
                        Legal notice
                      </Link>
                      <Link href="#" sx={{ color: "#fff" }}>
                        Cookie-Settings
                      </Link>
                    </Box>
                  </Box>
                  <Box component="div" className="text-right">
                    <Stack
                      display="flex"
                      justifyContent="end"
                      alignItems="center"
                      direction="row"
                      spacing={1}
                    >
                      <Typography component="a" sx={{ cursor: "pointer" }}>
                        <SnapchatIcon style={{ width: "24px" }} />
                      </Typography>
                      <Typography component="a" sx={{ cursor: "pointer" }}>
                        <LinkedinIcon style={{ width: "24px" }} />
                      </Typography>
                      <Typography component="a" sx={{ cursor: "pointer" }}>
                        <FacebookIcon style={{ width: "24px" }} />
                      </Typography>
                      <Typography component="a" sx={{ cursor: "pointer" }}>
                        <InstagramIcon style={{ width: "24px" }} />
                      </Typography>
                      <Typography component="a" sx={{ cursor: "pointer" }}>
                        <TwitterIcon style={{ width: "24px" }} />
                      </Typography>
                      <Typography component="a" sx={{ cursor: "pointer" }}>
                        <YoutubeIcon style={{ width: "24px" }} />
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </section>
            </Container>
          </footer>
        </Box>
      </Box>
    </>
  );
}

export default Footer;
