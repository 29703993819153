import axios from "axios";

export const authApiWithToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
console.log("process.env", process.env);

authApiWithToken.interceptors.request.use((request) => {
  try {
    request.headers["Authorization"] =
      `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzM5NDIzNjYwLCJpYXQiOjE3Mzg4MTg4NjAsImp0aSI6IjNkZjI5NzMyZDhjNjQ4YTViNjJjZDY2ZDA3OTcwYWUxIiwidXNlcl9pZCI6MTZ9.-G-YCnSf2pTi-MDw0dYnnm1SNW_mJsFAXEFTPOXOTpk`;
    return request;
  } catch (refreshError) {
    console.error("Refresh token failed:", refreshError);
    return Promise.reject(refreshError);
  }
});

export default authApiWithToken;
