import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  notificationSuccess,
  notificationFail,
} from "../slice/notificationSlice";
import { setLoader, setLandingData } from "../slice/landingSlice";
import authApiWithToken from "../../config/authApi";
import authApiWithOutToken from "../../config/authApiWithOutToken";

export const getLanding = createAsyncThunk(
  "getLanding",
  async (args, { dispatch }) => {
    dispatch(setLoader(true));
    try {
      const response = await authApiWithToken.get(
        `admin-service/portal/dynamic-content/`,
      );

      dispatch(
        setLandingData({
          ...response.data,
        }),
      );
      dispatch(setLoader(false));
      dispatch(notificationSuccess("Data Get Successfully"));
      return response.data;
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(notificationFail("Something Went Wrong!"));
    }
  },
);

export const getLandingBySection = createAsyncThunk(
  "userLogin",
  async (args, { dispatch }) => {
    dispatch(setLoader(true));
    try {
      const response = await authApiWithOutToken.get(
        `admin-service/portal/dynamic-content/?search=${args.section}`,
      );

      dispatch(
        args.landingSlice({
          ...response.data,
        }),
      );
      dispatch(setLoader(false));
      dispatch(notificationSuccess("Data Get Successfully"));
      return response.data;
    } catch (error) {
      dispatch(setLoader(false));
      dispatch(notificationFail("Something Went Wrong!"));
    }
  },
);
