import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import aboutThumb1 from "../../assets/images/home/about1.jpg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { getLandingBySection } from "../../store/thunk/landingThunk";
import { setAboutData } from "../../store/slice/landingSlice";
import { Heading } from "../heading";
import { PageContent } from "../pageContent";

// Custom SVG for the next arrow
const NextArrow = ({ onClick }) => (
  <div className="custom-arrow custom-next-arrow" onClick={onClick}>
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4l8 8-8 8"
        stroke="#404040"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

// Custom SVG for the previous arrow
const PrevArrow = ({ onClick }) => (
  <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 4l-8 8 8 8"
        stroke="#404040"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
);

const About = () => {
  const { t } = useTranslation();
  const language = i18next?.language;
  const dispatch = useDispatch();
  const { aboutData } = useSelector((state) => state.landing);
  useEffect(() => {
    dispatch(
      getLandingBySection({ section: "About", landingSlice: setAboutData }),
    );
  }, [dispatch]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // For tablets and smaller screens
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 768, // For mobile devices
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <PageContent>
      <Grid
        container
        spacing={1}
        sx={{
          ...(language === "ar" && {
            direction: "rtl",
          }),
          alignItems: "center",
          paddingTop: "50px",
        }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Box
            className={`plan-creditinfo ${language === "ar" ? "lg-ar" : "lg-en"}`}
            sx={{
              minHeight: "320px !important",
              px: 2,
              ...(language === "ar"
                ? {
                    direction: "ltr",
                    textAlign: "right",
                    "&::before": {
                      right: "-2.5px",
                    },
                  }
                : {
                    direction: "rtl",
                    textAlign: "left",
                    "&::before": {
                      left: "-2.5px",
                    },
                  }),
            }}
          >
            <Box>
              <Heading
                className={`about-heading ${language === "ar" ? "lg-ar" : "lg-en"}`}
                sx={{
                  minHeight: "320px !important",
                  px: 2,
                  ...(language === "ar"
                    ? {
                        direction: "ltr",
                        textAlign: "right",
                        "&::before": {
                          right: "-2.5px",
                        },
                      }
                    : {
                        direction: "rtl",
                        textAlign: "left",
                        "&::before": {
                          left: "-2.5px",
                        },
                      }),
                }}
              >
                <Box
                  component="span"
                  sx={{ "& p": { m: 0 } }}
                  dangerouslySetInnerHTML={{
                    __html:
                      language === "ar"
                        ? aboutData[1]?.text_to_display_ar
                        : aboutData[1]?.text_to_display,
                  }}
                />
              </Heading>
            </Box>
            <Box
              className="overview-info-list"
              component="div"
              sx={{
                ...(language === "ar"
                  ? {
                      direction: "rtl",
                      textAlign: "right",
                      "&::before": {
                        right: "-2.5px",
                      },
                    }
                  : {
                      direction: "ltr",
                      textAlign: "left",
                      "&::before": {
                        left: "-2.5px",
                      },
                    }),
              }}
            >
              <Typography variant="body">
                {/* {t(`about.description`)} */}
                <Box
                  component="span"
                  sx={{ "& p": { m: 0 } }}
                  dangerouslySetInnerHTML={{
                    __html:
                      language === "ar"
                        ? aboutData[0]?.text_to_display_ar
                        : aboutData[0]?.text_to_display,
                  }}
                />
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Slider {...settings} className="about-slider">
            <Box className="stacked-card">
              <img src={aboutThumb1} className="img-fluid" alt={aboutThumb1} />
            </Box>
            <Box className="stacked-card">
              <img src={aboutThumb1} className="img-fluid" alt={aboutThumb1} />
            </Box>
          </Slider>
        </Grid>
      </Grid>
    </PageContent>
  );
};

export default About;
