import React, { useState } from "react";
import {
  Box,
  Button,
  Link,
  TextField,
  Typography,
  CircularProgress,
  Stack,
  Grid,
} from "@mui/material";
import Logo from "../../assets/images/logo.svg";
import LogoXs from "../../assets/images/home/footer_logo.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import DialogBox from "../../components/DialogBox";

function Login() {
  const navigate = useNavigate();

  // State for form fields, error handling, and loading
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  // State for error messages
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  // Function to display error toast
  const showErrorToast = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    });
  };

  // Function to display success toast
  const showSuccessToast = (message) => {
    console.log("Success Toast Triggered", message); // Debugging line
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    });
  };

  const handleLoginClick = async () => {
    setUsernameError("");
    setPasswordError("");

    if (!username || !password) {
      if (!username) setUsernameError("Email is required");
      if (!password) setPasswordError("Password is required");
      showErrorToast("Both fields are required");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "http://173.230.134.14:8000/api/admin-service/accounts/login/",
        {
          username: username,
          password: password,
        },
      );

      console.log("Login Response:", response);

      if (response && response.status === 200) {
        console.log("Login Successful", response);
        showSuccessToast("Login successful!");
        navigate("/dashboard");
      } else {
        console.log("Unexpected Response", response);
        showErrorToast("Invalid username or password");
      }
    } catch (err) {
      console.log("Login Error:", err);

      if (err.response) {
        console.log("Error Response:", err.response);
        showErrorToast("Invalid username or password");
      } else {
        showErrorToast("An error occurred. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  const [open, setOpen] = useState({
    dialog: false,
    userId: 0,
  });

  return (
    <>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <CircularProgress size={40} color="primary" />
        </Box>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={5} order={{ xs: 2, sm: 1 }}>
          <Box component="div">
            <Box
              component="div"
              sx={{
                display: { xs: "none", sm: "block" },
                px: 3,
              }}
            >
              <img src={Logo} className="img-fluid" alt="Logo" />
            </Box>
            <Box component="div" px={4}>
              <Typography
                variant="h3"
                fontWeight="700"
                textTransform="uppercase"
              >
                Welcome to tvtc
              </Typography>
              <Typography
                variant="body"
                fontWeight="normal"
                textTransform="capitalize"
                fontStyle="italic"
              >
                Login to your account
              </Typography>

              <form className="theme-form">
                <Box className="form-group" component="div">
                  <Typography variant="body1" className="form-label">
                    Email
                  </Typography>
                  <TextField
                    margin="none"
                    fullWidth
                    variant="outlined"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    error={!!usernameError}
                    helperText={usernameError}
                  />
                </Box>
                <Box className="form-group" component="div">
                  <Typography variant="body1" className="form-label">
                    Password
                  </Typography>
                  <TextField
                    margin="none"
                    fullWidth
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!passwordError}
                    helperText={passwordError}
                  />
                </Box>
                <Box
                  className="form-group"
                  component="div"
                  sx={{ maxWidth: "220px" }}
                >
                  <Button
                    sx={{
                      background: "linear-gradient(to right, #4DADAD, #3564A6)",
                      color: "#fff",
                    }}
                    onClick={handleLoginClick}
                    fullWidth
                    disabled={loading}
                  >
                    LOGIN
                  </Button>
                </Box>
                <Box className="form-group" component="div">
                  <Typography variant="body">Don't have an account</Typography>
                  <div>
                    <Link href="#" underline="always">
                      Sign up
                    </Link>
                  </div>
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} order={{ xs: 1, sm: 2 }}>
          <Box
            sx={{
              backgroundImage: "url(/static/images/login_page.png)",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              minHeight: {
                xs: "400px",
                sm: "744px",
              },
            }}
          >
            <Box
              component="div"
              sx={{
                display: { sm: "none", xs: "block" },
                px: 3,
              }}
            >
              <img src={LogoXs} className="img-fluid" alt="Logo" />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {open?.dialog && (
        <DialogBox
          open={open?.dialog}
          maxWidth="sm"
          onClose={() => setOpen({ dialog: false, userId: 0 })}
        >
          <Stack p={2} spacing={1}>
            <Typography>nasser@mail.com</Typography>
            <Typography
              variant="h6"
              fontWeight="700"
              sx={{ textTransform: "uppercase" }}
            >
              Approve sign in request
            </Typography>
            <Typography>
              Open your authenticator app, and enter the number shown to sign
              in.
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                placeholder="Enter the number"
                margin="none"
                fullWidth
                variant="outlined"
                type="text"
                sx={{
                  width: "150px",
                }}
              />
            </Box>
            <Typography>
              No numbers in your app? Make sure to upgrade to the latest
              version.
            </Typography>
            <div>
              <Link href="#" underline="always">
                More information
              </Link>
            </div>
          </Stack>
        </DialogBox>
      )}
    </>
  );
}

export default Login;
