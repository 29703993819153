import axios from "axios";

export const authApiWithOutToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
console.log("process.env", process.env);

authApiWithOutToken.interceptors.request.use((request) => {
  try {
    return request;
  } catch (refreshError) {
    console.error("Refresh token failed:", refreshError);
    return Promise.reject(refreshError);
  }
});

export default authApiWithOutToken;
