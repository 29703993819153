import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  landingData: [],
  bannerData: [],
  aboutData: [],
  visionData: [],
  goalData: [],
  footerData: [],
  loading: false,
};

const landingSlice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    setLandingData: (state, action) => ({
      ...state,
      landingData: action.payload,
    }),
    setBannerData: (state, action) => ({
      ...state,
      bannerData: action.payload,
    }),
    setAboutData: (state, action) => ({
      ...state,
      aboutData: action.payload,
    }),
    setVisionData: (state, action) => ({
      ...state,
      visionData: action.payload,
    }),
    setGoalData: (state, action) => ({
      ...state,
      goalData: action.payload,
    }),
    setFooterData: (state, action) => ({
      ...state,
      footerData: action.payload,
    }),
    setLoader: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
  },
});

export const {
  setLandingData,
  setBannerData,
  setAboutData,
  setVisionData,
  setGoalData,
  setFooterData,
  setLoader,
} = landingSlice.actions;

export default landingSlice.reducer;
