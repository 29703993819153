import React from "react";
import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";

export const SelectInputField = ({
  label,
  name,
  value,
  handleChange,
  children,
  errors,
}) => {
  return (
    <>
      <FormControl fullWidth margin="normal" error={value ? false : !!errors}>
        <InputLabel id={name}>{label}</InputLabel>
        <Select
          name={name}
          labelId={name}
          value={value}
          onChange={handleChange}
        >
          {children}
        </Select>
        {value ? "" : <FormHelperText>{errors}</FormHelperText>}
      </FormControl>
    </>
  );
};
