import { Typography } from "@mui/material";

export const Heading = ({ children, className, textAlign }) => {
  return (
    <Typography
      variant="h1"
      fontWeight="700"
      textTransform="uppercase"
      className={className}
      sx={{
        mb: "29px",
        textAlign: textAlign,
        fontSize: { md: "50px", xs: "30px" },
      }}
    >
      {children}
    </Typography>
  );
};
