import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { PoliticalMap } from "../googleMap/politicalMap";
import { PageContent } from "../pageContent";
import { Heading } from "../heading";

const Whoweare = () => {
  const { t } = useTranslation();
  const language = i18next?.language;

  return (
    <PageContent>
      <Heading textAlign={language === "ar" ? "right" : "left"}>
        {t(`location.heading`)}
      </Heading>
      <Typography
        variant="body1"
        sx={{ pb: 5 }}
        textAlign={language === "ar" ? "right" : "left"}
      >
        {t(`location.subHeading`)}
      </Typography>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12}>
          {/* <img src={mapImg} className="img-fluid" /> */}
          {/* <Map /> */}
          <PoliticalMap />
        </Grid>
      </Grid>
    </PageContent>
  );
};

export default Whoweare;
