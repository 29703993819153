export const validateForm = (formData) => {
  const errors = {};
  let isValid = true;

  if (!formData?.email?.trim()) {
    errors.email = "Email is required";
    isValid = false;
  } else if (
    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      formData?.email.trim(),
    )
  ) {
    errors.email = "Invalid email address";
    isValid = false;
  }

  if (!formData?.name?.trim()) {
    errors.name = "Name is required";
    isValid = false;
  }

  if (!formData?.mobile) {
    errors.mobile = "Phone number is required";
    isValid = false;
  } else if (!/^05\d{8}$/.test(formData?.mobile)) {
    errors.mobile =
      "Invalid mobile: Must be 10 digits long, start with '05', and consist of numbers only.";
    isValid = false;
  }

  if (!formData?.inquiryType?.trim()) {
    errors.inquiryType = "Inquiry Type is required";
    isValid = false;
  }

  if (!formData?.messageTitle?.trim()) {
    errors.messageTitle = "Message Title is required";
    isValid = false;
  }

  if (!formData?.message?.trim()) {
    errors.message = "Message is required";
    isValid = false;
  }

  if (!formData?.verificationCode?.trim()) {
    errors.verificationCode = "Verification Code is required";
    isValid = false;
  } else if (formData?.verificationCode !== formData?.newCaptcha) {
    errors.verificationCode = "Incorrect CAPTCHA";
    isValid = false;
  }

  return { isValid, errors };
};
