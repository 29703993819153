import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

const DialogBox = ({ open, onClose, actions, children, maxWidth }) => {
  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth ? maxWidth : "lg"}
      open={open}
      onClose={onClose}
    >
      <>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Box component="img" src="/static/images/logo.svg" height="80px" />
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </>
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

export default DialogBox;
