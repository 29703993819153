import { FormControl, FormHelperText, Typography } from "@mui/material";
import { DocsUploading } from ".";

export const DocsUploadWithValidation = ({
  label,
  value,
  errors,
  accepted,
  onDrop,
}) => {
  return (
    <>
      <FormControl fullWidth margin="normal" error={value ? false : !!errors}>
        <Typography>{label}</Typography>
        <DocsUploading
          onDrop={onDrop}
          accepted={accepted}
          errors={value ? false : !!errors}
        />
        {value ? "" : <FormHelperText>{errors}</FormHelperText>}
      </FormControl>
    </>
  );
};
