import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Logo from "../../assets/images/home/footer_logo.svg";
import ArLogo from "../../assets/images/home/arabic_logo.svg";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import i18Next from "../../i18Next/config";
import headerData from "../../i18Next/locales/en.json";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

function Navbar() {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const [language, setLanguage] = useState("ar");

  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md")); // Check for screen size

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLoginClick = () => {
    // navigate("/login");
    window.location.href = "http://173.230.134.14:3001/";
  };

  useEffect(() => {
    i18Next.changeLanguage(language);
  }, [language]);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "unset",
        backgroundImage: "url(/static/images/home_header.png)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Container>
        <Toolbar sx={{ direction: language === "ar" && "rtl" }}>
          <Box sx={{ flexGrow: 0 }}>
            <Typography component={Link} to={"/"}>
              {language === "ar" ? (
                <img
                  src={ArLogo}
                  alt="logo"
                  className="logo"
                  height="70px"
                  width={isMobile ? "150px" : "auto"}
                />
              ) : (
                <img
                  src={Logo}
                  alt="logo"
                  className="logo"
                  height="70px"
                  width={isMobile ? "150px" : "auto"}
                />
              )}
            </Typography>
          </Box>

          {/* Navigation Menu for Large Screens */}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              direction: language === "ar" && "rtl",
            }}
          >
            {!isMobile && (
              <Box sx={{ direction: language === "ar" && "rtl" }}>
                {headerData?.navItems.map((page, i) => (
                  <Typography
                    key={page.label}
                    component={Link}
                    to={page.path}
                    sx={{
                      textDecoration: "none",
                      cursor: "pointer",
                      color: "#fff",
                      px: 2,
                    }}
                  >
                    {/* {page.label} */}
                    {t(`navItems.${i}.label`)}
                  </Typography>
                ))}
              </Box>
            )}
            <Button
              onClick={handleLoginClick}
              size="small"
              sx={{
                border: "1px solid #fff",
                color: "#fff",
                minWidth: { xs: 90, sm: 100 },
                padding: { xs: "4px 30px", sm: "4px 10px" },
                ml: language === "ar" ? 0 : 2,
                mr: language === "ar" ? 2 : 0,
              }}
            >
              {t(`login`)}
            </Button>
            {!isMobile && (
              <Box sx={{ minWidth: 20, mx: 1 }}>
                <FormControl fullWidth>
                  <Select
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    sx={{
                      border: "1px solid #fff",
                      color: "#fff",
                      fontWeight: "700",
                      height: "38px",
                      "& .MuiSelect-select": {
                        backgroundColor: "unset",
                      },
                    }}
                    variant="outlined"
                  >
                    <MenuItem value={"ar"}>آر</MenuItem>
                    <MenuItem value={"en"}>En</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            <IconButton
              onClick={() => setDrawerOpen(!drawerOpen)}
              sx={{ display: { md: "none" }, color: "#fff" }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          {/* Right-aligned section for search and cart icons on small devices */}
        </Toolbar>
      </Container>

      {/* Drawer for Mobile Navigation */}
      {isMobile && (
        <Drawer
          anchor={language === "ar" ? "left" : "right"}
          open={drawerOpen}
          onClose={handleDrawerClose}
          sx={{
            "& .MuiDrawer-paper": {
              width: 200,
              backgroundColor: "unset",
              backgroundImage: "url(/static/images/home_header.png)",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              direction: language === "ar" && "rtl",
            }}
          >
            {headerData?.navItems.map((page, i) => (
              <Typography
                key={page.label}
                component={Link}
                to={page.path}
                sx={{
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "#fff",
                  p: 1,
                }}
              >
                {/* {page.label} */}
                {t(`navItems.${i}.label`)}
              </Typography>
            ))}
          </Box>
        </Drawer>
      )}
    </AppBar>
  );
}

export default Navbar;
