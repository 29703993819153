import { Box, Container } from "@mui/material";

export const PageContent = ({ children, className }) => {
  return (
    <Box
      component="section"
      sx={{ padding: { sm: "100px 0px", xs: "56px 0px" } }}
      className={className}
    >
      <Container>{children}</Container>
    </Box>
  );
};
