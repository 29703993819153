import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Header from "../MainLayout/Header";
import Sidebar from "../MainLayout/Sidebar";

const drawerWidth = 280;
const closedDrawerWidth = 85;

function MainLayout({ children }) {
  const [open, setOpen] = useState(true);
  const handleDrawerToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <Header handleDrawerToggle={handleDrawerToggle} open={open} />
      <Sidebar open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        className="main-layout"
        sx={{
          flexGrow: 1,
          width: `calc(100% - ${!isSmallScreen ? (open ? drawerWidth : closedDrawerWidth) : 0}px)`,
          marginLeft: `${!isSmallScreen ? (open ? drawerWidth : closedDrawerWidth) : 0}px`,
          transition: "margin 0.3s ease, width 0.3s ease",
        }}
      >
        {children}
      </Box>
    </>
  );
}

export default MainLayout;
