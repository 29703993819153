import Dropzone from "react-dropzone";

export const DocsUploading = ({ onDrop, accepted, errors }) => {
  return (
    <Dropzone onDrop={onDrop} accept={accepted ? accepted : { "image/*": [] }}>
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          style={{
            border: errors ? "2px dashed #d32f2f" : "2px dashed #ccc",
            color: errors ? "#d32f2f" : "#404040",
            backgroundColor: "#F8F8F8",
            padding: "10px",
            textAlign: "center",
            borderRadius: "8px",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          Select Or Drag/drop Uploaded file here.
        </div>
      )}
    </Dropzone>
  );
};
