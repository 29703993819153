import themes from "./themes";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/styles.scss";
import "./i18Next/config";

import AppRoutes from "./layout/routes";

function App() {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <ToastContainer />
        <CssBaseline />
        <AppRoutes />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
