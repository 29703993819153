import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";

import goalImg from "../../assets/images/home/goal-thumb.jpg";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { getLandingBySection } from "../../store/thunk/landingThunk";
import { setGoalData } from "../../store/slice/landingSlice";
import { useTranslation } from "react-i18next";
import { PageContent } from "../pageContent";
import { Heading } from "../heading";

const Goals = () => {
  const { t } = useTranslation();
  const language = i18next?.language;
  const dispatch = useDispatch();
  const { goalData } = useSelector((state) => state.landing);
  useEffect(() => {
    dispatch(
      getLandingBySection({ section: "Goal", landingSlice: setGoalData }),
    );
  }, [dispatch]);

  return (
    <PageContent className="goal-area">
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{
          paddingTop: "50px",
          ...(language === "ar" && {
            direction: "rtl",
          }),
        }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <Box
            className={`plan-creditinfo ${
              language === "ar" ? "lg-ar" : "lg-en"
            }`}
            sx={{
              minHeight: "375px !important",
              px: 2,
              ...(language === "ar"
                ? {
                    textAlign: "right",
                    "&::before": {
                      right: "-2.5px",
                    },
                  }
                : {
                    "&::before": {
                      left: "-2.5px",
                    },
                  }),
            }}
          >
            <Heading
              className={`about-heading ${
                language === "ar" ? "lg-ar" : "lg-en"
              }`}
            >
              <Box
                component="span"
                sx={{ "& p": { m: 0 } }}
                dangerouslySetInnerHTML={{
                  __html:
                    language === "ar"
                      ? goalData[1]?.text_to_display_ar
                      : goalData[1]?.text_to_display,
                }}
              />
            </Heading>
            <Box sx={{ mt: 2 }} component="div" className="overview-info-list">
              <Box
                component="span"
                sx={{ "& p": { m: 0 } }}
                dangerouslySetInnerHTML={{
                  __html:
                    language === "ar"
                      ? goalData[0]?.text_to_display_ar
                      : goalData[0]?.text_to_display,
                }}
              />
              {/* <ul style={{ direction: language === "ar" && "rtl" }}>
                    {goalsData?.goals?.description?.map((val, i) => (
                      <li>
                        <Typography variant="body">
                          {t(`goals.description.${i}`)}
                        </Typography>
                      </li>
                    ))}
                  </ul> */}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <img src={goalImg} alt={goalImg} className="img-fluid" />
        </Grid>
      </Grid>
    </PageContent>
  );
};

export default Goals;
