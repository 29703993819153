import React from "react";
import Slider from "../../components/Home/Slider";
import Programs from "../../components/Home/Programs";
import Statistics from "../../components/Home/Statistics";
import About from "../../components/Home/About";
import Vision from "../../components/Home/Vision";
import Goals from "../../components/Home/Goals";
import Whoweare from "../../components/Home/Whoweare";

function Home() {
  return (
    <>
      <Slider />
      <About />
      <Programs />
      {/* <Chronicles /> */}
      {/* <LMSTimeline /> */}
      <Vision />
      <Goals />
      <Statistics />
      <Whoweare />
    </>
  );
}

export default Home;
