import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import programThumb1 from "../../assets/images/home/programs1.jpg";
import programThumb2 from "../../assets/images/home/programs2.jpg";
import programThumb3 from "../../assets/images/home/programs3.jpg";
import programThumb4 from "../../assets/images/home/programs4.jpg";
import programGifThumb1 from "../../assets/images/home/programs1.gif";
import programGifThumb2 from "../../assets/images/home/programs2.gif";
import programGifThumb3 from "../../assets/images/home/programs3.gif";
import programGifThumb4 from "../../assets/images/home/programs4.gif";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { PageContent } from "../pageContent";
import { Heading } from "../heading";

const Programs = () => {
  const { t } = useTranslation();
  const language = i18next?.language;

  const [hoveredImage, setHoveredImage] = useState(null);

  // Program data (images, descriptions, and GIF paths)
  const programs = [
    {
      id: 1,
      thumb: programThumb1,
      gif: programGifThumb1,
      description: t(`programs.description.0`),
    },
    {
      id: 2,
      thumb: programThumb2,
      gif: programGifThumb2,
      description: t(`programs.description.1`),
    },
    {
      id: 3,
      thumb: programThumb3,
      gif: programGifThumb3,
      description: t(`programs.description.2`),
    },
    {
      id: 4,
      thumb: programThumb4,
      gif: programGifThumb4,
      description: t(`programs.description.3`),
    },
  ];

  return (
    <PageContent className="bg-gray-color">
      <Grid container justifyContent="center" spacing={2} sx={{ mb: 5 }}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          sx={{ textAlign: { md: "center", xs: "left" } }}
        >
          <Box component="div">
            <Heading>{t(`programs.heading`)}</Heading>
            <Typography
              variant="body"
              textAlign={language === "ar" ? "right" : "left"}
            >
              {t(`programs.subHeading`)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        spacing={2}
        // sx={{
        //   display: "flex",
        //   flexWrap: { sm: "wrap", xs: "nowrap" },
        //   whiteSpace: { sm: "wrap", xs: "nowrap" },
        //   overflow: { sm: "unset", xs: "auto" },
        // }}
      >
        {programs.map((program) => (
          <Grid item xs={12} sm={6} md={3} key={program.id}>
            <Box
              component="div"
              className="program-box"
              onMouseEnter={() => setHoveredImage(program.id)}
              onMouseLeave={() => setHoveredImage(null)}
            >
              <Box component="div" sx={{ lineHeight: "0px" }}>
                <img
                  src={
                    hoveredImage === program.id ? program.gif : program.thumb
                  }
                  className="img-fluid"
                  alt={`Program ${program.id}`}
                  style={{ width: "100%" }}
                />
              </Box>
              <Box component="div" sx={{ mt: 2 }}>
                <Typography
                  variant="body"
                  component="p"
                  fontWeight="600"
                  sx={{ lineHeight: "22px" }}
                  textAlign={language === "ar" ? "right" : "left"}
                >
                  {program.description}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </PageContent>
  );
};

export default Programs;
