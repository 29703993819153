import { Box } from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { config } from "../../config";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 24.6513823, // Latitude for the center of the map
  lng: 46.7039145, // Longitude for the center of the map
};

const position = {
  lat: 24.6513823, // Latitude for the marker
  lng: 46.7039145, // Longitude for the marker
};
export const Map = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <LoadScript googleMapsApiKey={config?.googleKey}>
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={12}>
          <Marker position={position} />
        </GoogleMap>
      </LoadScript>
    </Box>
  );
};
