/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */
export default function themePalette(theme) {
  return {
    primary: {
      light: theme.colors.primaryLight,
      main: theme.colors.primary,
    },
  };
}
